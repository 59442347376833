<!-- Section / Portada -->
<section id="portada" class="portada portada2" *ngIf="imagenes_seccion_inicio[0]">
	<div class="imagen-principal">

        <img src="{{ url_imagenes_seccion_inicio + imagenes_seccion_inicio[0].nombre }}">

	</div>

    <div class="portada-titulo">
        <h1>Caritas pronto</h1>
    </div>
</section>

<section class="section portada portada2" *ngIf="imagenes_seccion_inicio[0]">
    <div class="section-portada-down">

        <img src="{{ url_imagenes_seccion_inicio + imagenes_seccion_inicio[1].nombre }}">

    </div>

	<div class="container inicio-texto">

        <p>
            Es una <b>caja de herramientas digitales</b> que permite registrar, <b>gestionar y procesar la información</b> obtenida en las distintas actividades de las Cáritas en sus distintos niveles. 
        </p>
        <p>
            <b>Optimiza y potencia el uso</b> del tiempo destinado a la gestión de los diversos procesos, mejorando la toma de decisiones en tiempo real.
        </p>
        <p>
            Permite tener siempre a mano y digitalizada la información de las Cáritas, generando listados, consultas y estadísticas que nos permiten tener una mirada más global de las tareas realizadas a fin de poder acompañar más y mejor a todos los beneficiarios.
        </p>
        <p>
            <b>¡Menos tiempo de gestión, más tiempo de servicio!</b>
        </p>
        <p>
            <br><br>
            <b>¿Cómo se usa Cáritas Pronto?</b><br>
            Es una aplicación web, por lo tanto, se puede acceder a ella desde cualquier dispositivo (celular, Tablet, notebook) que tenga acceso a internet.
        </p>
        <p>
            <br>
            <b>¿Quiénes pueden usarla?</b><br>
            En principio… ¡todos los que se animen! Posee una interfaz muy dinámica e intuitiva, fácil de utilizar. Es suficiente con saber cómo usar una casilla de mail.
        </p>
    </div>
</section>

<!-- Section / Nosotros -->
<section id="nosotros" class="section section-padding-bottom" *ngIf="web && web.nosotros">

    <div class="container">
    	<h2 class="section-titulo">Nosotros</h2>
        <div class="nosotros">

            <div class="nosotros-imagen animated">

	            <ng-template [ngIf]="imagenes_seccion_nosotros && imagenes_seccion_nosotros.length > 0" [ngIfElse]="imagenNosotrosDefault">
			        <img src="{{ url_web_inmobiliaria + imagenes_seccion_nosotros[0].nombre }}" alt="Nosotros">
			    </ng-template>

			    <ng-template #imagenNosotrosDefault>
			        <img src="{{ imagen_default_nosotros }}" alt="Nosotros">
			    </ng-template>

			</div>

	        <div class="nosotros-info">
	        	<div class="nosotros-titulo animated">
	        		<p class="font-size-1-2" [innerHtml]="web.nosotros_titulo"></p>
	        	</div>

		        <div class="nosotros-texto animated">
		        	<p [innerHtml]="web.nosotros_texto"></p>
		        </div>
		    </div>

        </div>

    </div>

</section>

<!-- Section / Servicios -->
<section id="servicios" class="section section-padding-top section-padding-bottom">

    <div class="servicios">

        <h2 class="section-titulo">Utilidades</h2>

        <div class="servicio" *ngFor="let servicio of servicios; odd as par;" [ngClass]="{ 'par': par }">

            <div class="servicio-info animated" [ngClass]="{ 'par': par }">
                <div class="servicio-info-bg-top-left">
                    <div class="servicio-info-cell"></div>
                    <div class="servicio-info-cell"></div>
                    <div class="servicio-info-cell"></div>
                </div>
                <h3 class="titulo">{{ servicio.titulo }}</h3>
                <p class="descripcion" [innerHtml]="servicio.descripcion"></p>
            </div>

            <figure class="servicio-imagen animated" [ngClass]="{ 'par': par }">

                <img src="{{ url_web_inmobiliaria + servicio.imagen }}" alt="Servicio caritaspronto">

            </figure>

        </div>

    </div>

</section>

<!-- Section / Contactos -->
<section id="contactos" class="section">

	<div class="container">
		<h2 class="section-titulo">Contactos</h2>
        <div class="contactos">

            <div class="datos">
                <img class="background-logo-caritas" src="/assets/images/solo-logo.svg">

            	<h2>{{ inmobiliaria.nombre }}</h2>

                <address>
                   Dirección<br>
                </address>

                <a href="tel:+542235190441">
					<p>----</p>
				</a>

				<a href="tel:+54223">
					<p>----</p>
				</a>

				<a href="mailto:consulta@caritaspronto.org.ar">
					<p>consulta@caritaspronto.org.ar</p>
				</a>

                <div class="contactos-social">

					<a href="https://www.facebook.com/nose" target="_blank">
	                	<i class="mdi mdi-facebook"></i>
	                </a>
	                <a href="https://www.instagram.com/nose" target="_blank">
	                	<i class="mdi mdi-instagram"></i>
	                </a>

				</div>

                <div class="horarios">
                    <h4>Horario de atención</h4>

                    <p>---</p>
                </div>

            </div>

        </div>
    </div>

    <div class="mapa" *ngIf="inmobiliaria.latitud && inmobiliaria.longitud">

        <app-mapa-inmuebles #mapainmobiliaria
            [marker]="inmobiliaria"
            [disableDefault]="true"
            [scrollwheel]="false"
            [mapDraggable]="false"
        >
        </app-mapa-inmuebles>

    </div>

</section>
