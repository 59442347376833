import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { fromEvent, Observable, empty } from 'rxjs';
import { map, share, auditTime, pairwise, distinctUntilChanged, filter } from 'rxjs/operators';

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Injectable({
  providedIn: 'root'
})

export class ScrollService {

  public scroll$:Observable<any>;
  public scrollUp$: Observable<any>;
  public scrollDown$: Observable<any>;

  constructor(
    @Inject(DOCUMENT) private document:any,
    @Inject(PLATFORM_ID) private platformId: Object
  ){
    // Si se ejecuta del lado del servidor, window no esta definido en node
    // por tal motivo, pregunto si estoy en un navegador
    if(isPlatformBrowser(this.platformId)){
      // fromEvent crea un Observable a partir de un evento
      this.scroll$ = fromEvent(window, 'scroll').pipe(
        auditTime(200),
        // Para compatibilidad entre navegadores, es recomendable usar window.pageYOffset en vez de window.scrollY
        map(() => window.pageYOffset),
        pairwise(), // Pasa el ultimo y ante-ultimo valor [ 132, 158]
        map(([y1, y2]): any => (y2 < y1 ? { direction: Direction.Up, valor: y2 } : { direction: Direction.Down, valor: y2 })),
        distinctUntilChanged(), // devuelve una secuencia observable que contiene solo valores continuos diferentes
        share() // evita las multiples subscripciones
      );

      this.scrollUp$ = this.scroll$.pipe(
        filter(scroll => scroll.direction === Direction.Up)
      );

      this.scrollDown$ = this.scroll$.pipe(
        filter(scroll => scroll.direction === Direction.Down)
      );

    } else{
      this.scroll$ = empty();
      this.scrollUp$ = empty();
      this.scrollDown$ = empty();
    }
  }

    scrollTo(param: any = { x:0, y:0, duration: 0 }){

        let { x, y, duration } = param;

        $('html, body').animate({
	        scrollTop: y
	    }, duration );
    }
}