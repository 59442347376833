import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Gestos para mobile
// aquí puede agregar su propia configuración de gestos Hammer usando la clase HammerGestureConfig y HAMMER_GESTURE_CONFIG 
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

/* PROBLEMA NO RESUELTO

EXPLICACION: uso dos librerias hammerjs
---------------------------------------
@egjs/hammerjs : esta no trae el problema : window is not defined
y por eso lo declaro aca, pero no puedo usarla en los componentes como por carousel, porque
no me toma los gestos, para ampliar y desplazar etc ..
por eso tengo que y usar el hammerjs original en los componentes
*/
import * as Hammer from '@egjs/hammerjs';

import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class MyHammerConfig extends HammerGestureConfig {

    overrides = <any>{
        'swipe': {direction: Hammer.DIRECTION_ALL}, // override default settings
        'pan': {direction: Hammer.DIRECTION_ALL}
    };
}

// Pipe para sanitizar una URL
import { UrlSeguraPipe } from './pipes/url-segura.pipe';

// Componentes externos
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Componentes
import { T011MainComponent } from './components/main/main.component';
import { T011InicioComponent } from './components/inicio/inicio.component';


// Componentes independientes
import { CarouselImagenesComponent } from './components/globals/carousel-imagenes/carousel-imagenes.component';
import { SliderImagenesComponent } from './components/globals/slider-imagenes/slider-imagenes.component';
import { CodigoQRComponent } from './components/globals/codigo-qr/codigo-qr.component';
import { GalleryComponent } from './components/globals/gallery/gallery.component';
import { MapaInmueblesComponent } from './components/globals/mapa-inmuebles/mapa-inmuebles.component';
import { SpinnerComponent } from './components/globals/spinner/spinner.component';

@NgModule({
  declarations: [
    UrlSeguraPipe,
    AppComponent,
    T011MainComponent,
    T011InicioComponent,
    CarouselImagenesComponent,
    SliderImagenesComponent,
    CodigoQRComponent,
    GalleryComponent,
    MapaInmueblesComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
  	ReactiveFormsModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCSEOl4UiF58WuPrLjTMvhzprkHE79rRVE'
    }),
    HammerModule
  ],
  providers: [
  	Title, // Por recomendacion de angular.io, dice que es mejor registrar el servicio desde el arranque
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
