import { Component, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

// Servicios
import { GLOBAL } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';

@Component({
	selector: 'T011-main',
	templateUrl: './main.component.html',
	styleUrls: [
		'./common.css',
		'./main.component.css'
	],
	encapsulation: ViewEncapsulation.None
})

export class T011MainComponent {

	public identity: any;
	public inmobiliaria: any;
	public web: any;
    public dominio: string;

	public url_web_inmobiliaria: string;

	private subscriptionScroll: Subscription;

	constructor(private _cdRef: ChangeDetectorRef,
				private _scrollService: ScrollService,
                private _titleService: Title,
                private _metaService: Meta,
                @Inject(PLATFORM_ID) private platformId: Object,
                private renderer: Renderer2 ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = null; //GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;
        this.dominio = GLOBAL.dominio;

		this.url_web_inmobiliaria =  ''; //GLOBAL.url_webs + GLOBAL.inmobiliaria.id + '/';
	}

	ngOnInit(){

	    console.log('T011MainComponent component cargado ... ');

	    this.setEtiquetaTitleHTML();
        this.setTagDescription();
        this.setTagkeywords();
        this.setIconoDeLaPagina();

	    this.subscriptionScroll = this._scrollService.scroll$.subscribe(scroll => {
										this.checkScroll(scroll);
								    });
	}

    ngAfterViewInit() {

        if ( isPlatformBrowser(this.platformId) )
            this.hideAppLoading();
    }

	ngOnDestroy(){

		this.subscriptionScroll.unsubscribe();
	}

	setEtiquetaTitleHTML(){

		this._titleService.setTitle( 'Cáritas Pronto' );
	}

    setTagDescription(){

        this._metaService.updateTag({ name: 'description', content: 'hola' });
    }

    setTagkeywords(){

        this._metaService.updateTag({ name: 'keywords', content: ' palabaras claves' });
    }

    setIconoDeLaPagina() {

        let icon = <HTMLLinkElement> document.querySelector('#pagina-icono');

        icon.href = '/assets/images/icono-caritas.png';
    }

    hideAppLoading() {

        let loader = this.renderer.selectRootElement('#app-loading');

        if ( loader.style.display != "none")
            loader.style.display = "none";
    }

	toggleMobileMenu(){

		let button = $('.boton-menu');

		if (button.hasClass("open")) {
			button.removeClass('open');
			$('.site-menu-mobile').removeClass('show');
		} else {
			button.addClass('open');
			$('.site-menu-mobile').addClass('show');
		}
	}

	checkScroll(scroll:any={}){
		//console.log('scroll: ', scroll);

		if ( scroll.direction == 'Down' ) {
			$('header').removeClass('scroll-up');
			$('header').addClass('scroll-down');

			if ( scroll.valor == 0 ) {
				$('header').removeClass('scroll-down');
				$('header').removeClass('scroll-up');
			}

		} else if ( scroll.direction == 'Up' ) {

			$('header').removeClass('scroll-down');

			$('header').addClass('scroll-up');

			if ( scroll.valor == 0 ) {
				$('header').removeClass('scroll-down');
				$('header').removeClass('scroll-up');
			}
		}
	}
}
