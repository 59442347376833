import { NgModule } from '@angular/core';
import { Routes, RouterModule } from'@angular/router';

// Components
import { T011MainComponent } from './components/main/main.component';
import { T011InicioComponent } from './components/inicio/inicio.component';


const routes: Routes = [
  {
    path: '',
    component: T011MainComponent,
    children: [
      {path: '', component: T011InicioComponent},
      {path: 'inicio', component: T011InicioComponent},
      {path: 'nosotros', component: T011InicioComponent},
      {path: 'servicios', component: T011InicioComponent},
      {path: 'contactos', component: T011InicioComponent},
      {path: '**', component: T011InicioComponent}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
 // <-- debugging purposes only
    )
  ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }