<div class="mapa-inmuebles" [ngStyle]="{ 'width': width, 'height': height }">

    <agm-map #mapa
    	[latitude]="centroLatitud" 
    	[longitude]="centroLongitud"
        [mapTypeId]="mapa_tipo"
        [zoom]="zoom"
        [fitBounds]="con_limites"
        [ngClass]="'agm-map'"
        [disableDefaultUI]="disableDefault"
        [zoomControl]="!disableDefault"
        [streetViewControl]="!disableDefault"
        [scrollwheel]="scrollwheel"
        [mapDraggable]="mapDraggable"
        (mapReady)="mapaInicializado()"
        >

        <!-- Esto es para marcar una sp;a ubicacion por ejemplo: la inmobiliaria -->
        <agm-marker *ngIf="marker"
            [latitude]="marker.latitud"
            [longitude]="marker.longitud"
            [markerDraggable]="false"
        >        
        </agm-marker>

        <agm-marker
            *ngFor="let inmueble of inmuebles"
            [latitude]="inmueble.latitud"
            [longitude]="inmueble.longitud"
            [markerDraggable]="false"
            (markerClick)="selectInmueble(mapa, info_window)"
            (mouseOver)="onMouseOverInmueble(mapa, info_window)"
            [agmFitBounds]="true" 
        >

            <agm-info-window #info_window 
                [disableAutoPan]="false" 
            >
                
                
            </agm-info-window>
            
        </agm-marker>

    </agm-map>

</div>