import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
//import * as Hammer from 'hammerjs';

let Hammer
// Client-side
try {
  Hammer = require('hammerjs')
}
// SSR, (renderizado del lado del servidor ) pass el objeto vacio
catch (err) {
  Hammer = {}
}

@Component({
	selector: 'app-carousel-imagenes',
	templateUrl: './carousel-imagenes.component.html',
	styleUrls: ['./carousel-imagenes.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarouselImagenesComponent{

	@Input() images: Array<any>;
	@Input() currentImage: number;	
	@Input() pathDirImages: string;
	@Input() tiempoPausa: number;
	@Input() velocidadEfecto: number;
	@Input() width: string;
	@Input() height: string;
	@Input() object_fit: string;
	@Input() show_progress_bar: 'true' | 'false' | boolean;
	@Input() show_zoom_button: 'true' | 'false' | boolean;
	@Input() show_nav_buttons: 'true' | 'false' | boolean;
	@Input() show_pause_button:'true' | 'false' | boolean;
	@Input() show_indicators: 'true' | 'false' | boolean;
	@Input() show_image_text: 'true' | 'false' | boolean;
	@Input() activateCarousel: 'true' | 'false' | boolean;

	@Output() onZoomImagen = new EventEmitter<any>();

	public paused: boolean;

	private timerRef: any = null;

	public imagesCount: number;
	public desplazar: number;

	public touch_device: boolean; 
	private touchMoving: boolean;
	private agregarUltimoAlComienzo: boolean;
	private animacion_en_curso: boolean;

	constructor(private _cdRef: ChangeDetectorRef){

		//VALORES POR DEFECTO
		this.images = [];
		this.paused = false;

		this.currentImage = 0;
		this.imagesCount = 0;

		this.tiempoPausa = 10000; // 10 segundos por defecto
		this.velocidadEfecto = 1000;

		// Valores del tamaño por defecto del carousel
		this.width = '100%';
		this.height = '400px';

		// No le pongo por defecto, porque la clase CSS es 'contain' por defecto
		this.object_fit = null; 
		
		this.show_progress_bar = true;
		this.show_zoom_button = false;
		this.show_nav_buttons = true;
		this.show_pause_button = true;
		this.show_indicators = true;
		this.show_image_text = false;
		this.activateCarousel = true;

		this.touch_device = false;
		this.desplazar = 0;
		this.agregarUltimoAlComienzo = true;
		this.animacion_en_curso = false;
	}

	@HostListener("window:resize", ['$event']) onWindowResize(evento: Event) {

		this.setting();
	}

	ngOnChanges(change){

		if ( typeof this.show_progress_bar == 'string' )
			this.show_progress_bar =  this.show_progress_bar == 'true' ? true : false;
		else if ( typeof this.show_progress_bar != 'boolean' )
			this.show_progress_bar = true;

		if ( typeof this.show_zoom_button == 'string' )
			this.show_zoom_button =  this.show_zoom_button == 'true' ? true : false;
		else if ( typeof this.show_zoom_button != 'boolean' )
			this.show_zoom_button = false;

		if ( typeof this.show_nav_buttons == 'string' )
			this.show_nav_buttons =  this.show_nav_buttons == 'true' ? true : false;
		else if ( typeof this.show_nav_buttons != 'boolean' )
			this.show_nav_buttons = true;

		if ( typeof this.show_pause_button == 'string' )
			this.show_pause_button =  this.show_pause_button == 'true' ? true : false;
		else if ( typeof this.show_pause_button != 'boolean' )
			this.show_pause_button = true;

		if ( typeof this.show_indicators == 'string' )
			this.show_indicators =  this.show_indicators == 'true' ? true : false;
		else if ( typeof this.show_indicators != 'boolean' )
			this.show_indicators = true;

		if ( typeof this.show_image_text == 'string' )
			this.show_image_text =  this.show_image_text == 'true' ? true : false;
		else if ( typeof this.show_image_text != 'boolean' )
			this.show_image_text = false;

		if ( typeof this.activateCarousel == 'string' )
			this.activateCarousel =  this.activateCarousel == 'true' ? true : false;
		else if ( typeof this.activateCarousel != 'boolean' )
			this.activateCarousel = true;

		this.setting();		
	}

	ngOnInit(){

		if ( this.isTouchDevice() ) {
			this.touch_device = true;
			this.show_nav_buttons = false;		
		}
	}

	ngAfterViewInit() {
		// Esto esta hecho para que renderice el componente, despues un 1 1/14 segundos

		setTimeout(() => {
			  this.setting();
		}, 1250);
	}

	ngOnDestroy(){
		this.clearInterval();
	}

	detectChanges(){

		this._cdRef.detectChanges();
	}

	isTouchDevice(){

		return (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
	}
	
	setting(){

		this.setPropiedadesElementos().subscribe(
			(success)=>{

				if ( this.activateCarousel )
		           	this.playCarousel();
			});        
	}

	// Este metodo se ejecuta primero que el inicio del efecto, porque
	// primero tengo que setear los valores de ancho del carousel-container,
	// carousel, e image
	setPropiedadesElementos(): Observable<boolean>{

		return new Observable((observer) => {
			// Tengo que forzar a reconocer los cambios en el DOM, 
			// porque sino no me reconoce las imagenes creadas
			this.detectChanges();

			// Seteo las caracteristicas que tienen que tener los items del slider
			// -------------------------------------------------------------------

			if ( this.object_fit ) {
				let prop_css_image = {
					'object-fit': this.object_fit
				}

				$('.carousel-imagenes > .carousel .image img').css(prop_css_image);
			}

			// Seteo el ancho del carousel
			let prop_css = {
				'width': this.width,
				'height': this.height
			}

			$('.carousel-imagenes').css(prop_css);

			this.desplazar = $('.carousel-imagenes').width();

			// Seteo el ancho de cada imagen
			$('.carousel-imagenes > .carousel > .image').css('width', this.desplazar);

			observer.next(true);
			observer.complete();
		});
	}

	onTap(index){

		if ( this.touch_device ) // es realmente un Tap sino es un Click
			this.onZoomImagen.emit({ index });
	}

	onZoom(){

		this.onZoomImagen.emit({ index: this.currentImage });
	}

	onPanStart(event){

		this.touchMoving = true;

		// paro animacion aunque no deberia porque no esta en curso
		this.stopCarousel();

		$('.carousel-imagenes > .carousel').removeClass('animate');
	}

	onPanMove(event){		

		if ( !this.animacion_en_curso && ( event.direction == Hammer.DIRECTION_LEFT || event.direction == Hammer.DIRECTION_RIGHT ) ) {

			if ( event.direction == Hammer.DIRECTION_RIGHT && this.agregarUltimoAlComienzo ) { // --> Si es anterior

				$('.carousel-imagenes > .carousel').prepend( $('.image:last') );

				$(".carousel-imagenes > .carousel").css({ transform: 'translateX(-' + ( this.desplazar - event.distance ) + 'px)' });

				this.agregarUltimoAlComienzo = false;
			} 

			if ( event.direction == Hammer.DIRECTION_RIGHT && !this.agregarUltimoAlComienzo )
				$(".carousel-imagenes > .carousel").css({ transform: 'translateX(-' + ( this.desplazar - event.distance ) + 'px)' });
			
			if ( event.direction == Hammer.DIRECTION_LEFT )
				$(".carousel-imagenes > .carousel").css({ transform: 'translateX(-' +  event.distance  + 'px)' });
		}
	}

	onPanEnd(event){

		if ( !this.animacion_en_curso && ( event.direction == Hammer.DIRECTION_LEFT || event.direction == Hammer.DIRECTION_RIGHT ) ) {

			if ( event.direction == Hammer.DIRECTION_LEFT ) { // <--

				this.currentImage = (this.currentImage + 1 >= this.images.length ) ? 0 : this.currentImage +1;

				this.detectChanges();

				this.activeIndicator();

				this.doEfectoNext();

				this.playCarousel();

			} else if ( event.direction == Hammer.DIRECTION_RIGHT ) { 

				this.currentImage = (this.currentImage - 1) < 0  ? this.images.length - 1 : this.currentImage -1;

				this.detectChanges();

				this.activeIndicator();

				this.animacion_en_curso = true;
					
				$('.carousel-imagenes > .carousel')
					.addClass('animate')
					.css({ transform: 'translateX(0px)' });

				this.animacion_en_curso = false;				

				this.playCarousel();
				
			}
		} else // Reinicio el efecto porque en el panstart lo pare
			this.playCarousel();

		this.touchMoving = false;
		this.agregarUltimoAlComienzo = true;		
	}

	playCarousel(){

		// El script del efecto se producira si y solo si images es mayor a 1
		if ( this.activateCarousel && this.images.length > 1 ) {

			this.clearInterval();

			if ( ! this.paused )
				this.startEfectoBarra();

			// Ahora creo la rutina
			// --------------------
			this.timerRef = setInterval(()=>{

				if ( !this.touchMoving && ! this.paused && document.visibilityState == "visible" ) {
					// Paso a la siguiente imagen, si llego a la ultima, vuelvo al principio
					this.currentImage = (this.currentImage + 1 >= this.images.length ) ? 0 : this.currentImage + 1;
					
					this.detectChanges();

					this.activeIndicator();

					this.doEfectoNext();
				    this.startEfectoBarra();
				}

		    }, this.tiempoPausa);
		}
	}	

	previous(){

		if ( this.animacion_en_curso ) return;

		this.currentImage = (this.currentImage - 1) < 0  ? this.images.length - 1 : this.currentImage - 1;
		
		this.detectChanges();

		this.activeIndicator();

		this.doEfectoPrevious();

		this.playCarousel();
	}

	next(){

		if ( this.animacion_en_curso ) return;
		
		this.currentImage = (this.currentImage + 1 >= this.images.length ) ? 0 : this.currentImage +1;
		
		this.detectChanges();

		this.activeIndicator();

		this.doEfectoNext();

		this.playCarousel();
	}

	doEfectoPrevious(){
		
		this.animacion_en_curso = true;

		$('.carousel-imagenes > .carousel')
			.removeClass('animate')
			.prepend( $('.image:last') )
			.css({ transform: 'translateX(-' + this.desplazar + 'px)' });

		setTimeout(() => {
			
			$('.carousel-imagenes > .carousel')
				.addClass('animate')
				.css({ transform: 'translateX(0px)' });

			this.animacion_en_curso = false;
		}, 100);
	}

	doEfectoNext(){

		this.animacion_en_curso = true;

		$('.carousel-imagenes > .carousel')
					.addClass('animate')
					.css({ transform: 'translateX(-' + this.desplazar + 'px)' });

		setTimeout(() => {
			
			$('.carousel-imagenes > .carousel')
					.append( $('.image:first') )
					.removeClass('animate')
					.css({ transform: 'translateX(0px)' });

			this.animacion_en_curso = false;
		}, 600);	    
	}

	pausa() {
		this.paused = !this.paused;

		if ( this.paused )
			this.stopCarousel();
		else
			this.playCarousel();
	}

	activeIndicator() {

		if ( this.show_indicators )
			this.detectChanges();
	}

	stopCarousel(){

		this.stopEfectoBarra();
		this.clearInterval();
	}

	private startEfectoBarra() {

		if ( this.show_progress_bar ) {
			this.stopEfectoBarra();
			
			setTimeout(() => {
				$('.carousel-imagenes > .barra')
					.addClass('animate-barra')
					.css('width', '100%');
			}, 100);
		} 
    }

	private stopEfectoBarra() {
		
		if ( this.show_progress_bar ) {
			$('.carousel-imagenes > .barra')
				.removeClass('animate-barra')
				.css('width', '0%');
		}
	}	

	private clearInterval(){

	    if (this.timerRef) {
	      	clearInterval(this.timerRef);
	      	this.timerRef = null;
	    }
	}
}