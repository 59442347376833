import { Component, Input, ViewChild, ElementRef } from '@angular/core';

import { AgmMap } from '@agm/core';


@Component({
	selector: 'app-mapa-inmuebles',
	templateUrl: './mapa-inmuebles.component.html',
	styleUrls: ['./mapa-inmuebles.component.css']
})

export class MapaInmueblesComponent {

	public identity: any;

	public centroLatitud: number;
	public centroLongitud: number;
	public zoom: number;
	public mapa_tipo: "roadmap" | "hybrid" | "satellite" | "terrain";
	public con_limites: boolean;

	@Input() inmuebles: Array<any>;
	@Input() marker: any;
	@Input() width: number | string;
	@Input() height: number | string;
	//@Input() showInfoWindow: 'true' | 'false' | boolean;
	@Input() disableDefault: 'true' | 'false' | boolean;
	@Input() scrollwheel: 'true' | 'false' | boolean;
    @Input() mapDraggable: 'true' | 'false' | boolean;


	@ViewChild(AgmMap, { static: true }) mapa: AgmMap;

	constructor() {

		this.centroLatitud = -38.00418;
		this.centroLongitud = -57.54206;
		this.zoom = 15;
		this.mapa_tipo = "roadmap";
		this.con_limites = true;

		this.inmuebles = [];
		this.marker = null;

		this.width = '100%';
		this.height = '100%';
		//this.showInfoWindow = true;
		this.disableDefault = false;
		this.scrollwheel = true;
        this.mapDraggable = true;
	}

	ngOnChanges(){
		
		if ( this.marker ) {
			this.con_limites = false;
			this.centroLatitud = this.marker.latitud ? this.marker.latitud : null;
			this.centroLongitud = this.marker.longitud ? this.marker.longitud : null;
		}
/*
		if ( typeof this.showInfoWindow == 'string' )
			this.showInfoWindow = this.showInfoWindow == 'true' ? true: false;
*/
		if ( typeof this.disableDefault == 'string' )
			this.disableDefault = this.disableDefault == 'true' ? true: false;

		if ( typeof this.scrollwheel == 'string' )
			this.scrollwheel = this.scrollwheel == 'true' ? true: false;

        if ( typeof this.mapDraggable == 'string' )
			this.mapDraggable = this.mapDraggable != 'false' ? true: false;


		this.clearLastOpen();
	}	

	ngOnInit(){

		console.log('MapaInmueblesComponent component cargado ... ');		
	}

	clearLastOpen(){

		//console.log('mapa: ', this.mapa);

		if ( this.mapa && this.mapa.hasOwnProperty('lastOpen') )
			this.mapa = Object.assign(this.mapa, { lastOpen: null });
	}

	mapaInicializado(){

		console.log('entro al mapaInicializado');
	}
	

	max(coordType: 'latitud' | 'longitud'): number {
    	return Math.max(...this.inmuebles.map(inmueble => inmueble[coordType]));
  	}

  	min(coordType: 'latitud' | 'longitud'): number {
    	return Math.min(...this.inmuebles.map(inmueble => inmueble[coordType]));
  	}

  	selectInmueble(mapa, info_window) {

	  	this.openInfoWindow(mapa, info_window);	  	
	}

  	onMouseOverInmueble(mapa, info_window) {

  		this.openInfoWindow(mapa, info_window);        		
    }

    openInfoWindow(mapa, info_window) {

    	if ( mapa && mapa.lastOpen )
            mapa.lastOpen.close();

    	mapa.lastOpen = info_window;

        if ( info_window )
	        info_window.open();
	}
}