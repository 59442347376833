import { Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription, forkJoin } from 'rxjs';

// Servicios
import { GLOBAL, TIPO_VISTA } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';
import { ConfigService } from '../../services/config.service';


@Component({
	selector: 'T011-inicio',
	templateUrl: './inicio.component.html',
	styleUrls: [
        './inicio.component.css',
        '../../../assets/css/form.css',
        '../../../assets/css/animations.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class T011InicioComponent {

	public identity: any;
	public inmobiliaria: any;
	public web: any;
	public importantes: Array<any>;
	public inmuebles: Array<any>;
	public servicios: Array<any>;
	public agentes: Array<any>;
	
	public imagenes_seccion_inicio: Array<any>;
	public imagenes_seccion_nosotros: Array<any>;

	public imagen_default_inmueble: string;
	public imagen_default_nosotros: string;
	public imagen_default_agente: string;
	public imagen_default_servicios: string;

	public url_web_inmobiliaria: string;
    public url_imagenes_seccion_inicio: string;
	public url_thumbnails_inmuebles: string;
	public url_images_agentes: string;

	public tipos_operacion: Array<any>;
	public tipos_inmueble: Array<any>;
	public barrios: Array<any>;
	public precios_venta: Array<any>;
	public precios_alquiler: Array<any>;
	public superficies_cochera: Array<any>
	public superficies_local: Array<any>;
	public expensas: Array<any>;
	public superficies_galpon: Array<any>;
	public superficies_terreno: Array<any>;
	public searchFields: any;
	public searchPropertys: any;
    public esperandoRespuestaDelBuscador: boolean;
    public ya_realizo_busqueda: boolean;

	// Selects
	public fcTipoOperacion: FormControl;
	public fcTipoInmueble: FormControl;
	public fcBarrios: FormControl;
	public fcPrecioVenta: FormControl;
	public fcPrecioAlquiler: FormControl;
	public fcAmbientes: FormControl;
	public fcDormitorios: FormControl;
	public fcBanos: FormControl;
	public fcCocheras: FormControl;
	public fcPlantas: FormControl;
	public fcDependencia: FormControl;
	public fcSupCochera: FormControl;
	public fcTipoCobertura: FormControl;
	public fcTipoCoche: FormControl;
	public fcSupLocal: FormControl;
	public fcDisposicion: FormControl;
	public fcExpensas: FormControl;
	public fcAptoProfesional: FormControl;
	public fcSupGalpon: FormControl;
	public fcSupCubiertaLocal: FormControl;
	public fcSupTerreno: FormControl;

	private subscriptionScroll: Subscription;

	public tipo_vista: string;

	private observador_de_intersecciones: any;
	private observador_de_intersecciones_agentes: any;


	constructor(private _cdRef: ChangeDetectorRef,
				private _route: ActivatedRoute,
				private _router: Router,
                private _scrollService: ScrollService,
				private _configService: ConfigService,) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = {}; //GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;
		
		this.servicios = [];
		this.agentes = [];
		this.imagenes_seccion_inicio = [];
		this.imagenes_seccion_nosotros = [];
		
		this.importantes = [];
		this.inmuebles = [];

		this.imagen_default_inmueble = this._configService.imagen_default_inmueble;
		this.imagen_default_nosotros = this._configService.imagen_default_nosotros;
		this.imagen_default_agente = this._configService.imagen_default_agente;
		this.imagen_default_servicios = this._configService.imagen_default_servicios;
		
       
        this.url_imagenes_seccion_inicio = '/assets/images/';
        this.url_web_inmobiliaria = '/assets/images/';



		this.tipos_operacion = [];
		this.tipos_inmueble = [];
		this.precios_venta = [];
		this.precios_alquiler = [];
		this.superficies_cochera = [];
		// Este array lo uso para comercios y locales
		this.superficies_local = [];
		this.expensas = [];
		this.superficies_galpon = [];
		this.superficies_terreno = [];

		this.searchFields = {};
		

		this.tipo_vista = TIPO_VISTA.GRILLA;
		this.ya_realizo_busqueda = false;

		this.observador_de_intersecciones = null;

        this.servicios = [
            { titulo: 'Gestión', descripcion: 'Permite tener digitalizados y siempre a mano, los datos de los beneficiarios vinculados a la Cáritas parroquial. Se pueden acceder a los legajos personales de cada beneficiario aplicando diferentes búsquedas como por ejemplo DNI, apellido, nombre y visualizarlos de manera geolocalizada.', imagen: 'servicios-gestion.png' },
            { titulo: 'Simplicidad', descripcion: 'Permite, entre otras ventajas generar una planilla de entrega mensual: con los beneficiarios que reciben alimentos. Con un solo clic, se descarga en PDF. ¡Lista para imprimir! ', imagen: 'servicios-simplicidad.jpg' },
            { titulo: 'Optimización', descripcion: 'Al digitalizar aquellos procesos que muchas veces se hacen manualmente, permite disponer más tiempo para tomar mejores decisiones, al elaborar con datos propios estadísticas, gráficos y filtros de búsqueda.', imagen: 'servicios-optimizacion.png' }
        ];
	}
	
	ngOnInit(){
		console.log('T011InicioComponent component cargado ... ');

        
        this.setImagenesInicioPorDefecto();

        this.setSubscripciones(); 
        
        this.setObservadorDeIntersecciones();        

        // Cuando inicia el componente, voy a la seccion que dice el 
        // hash # ( fragment ) de la url, despues de un tiempo, 
        // para que se carguen los datos de la pagina
        setTimeout(()=>{
            this.readSeccionURL();
        }, 1500);
	}

	ngOnDestroy(){

		if ( this.observador_de_intersecciones )
			this.observador_de_intersecciones.disconnect();

		if ( this.observador_de_intersecciones_agentes )
			this.observador_de_intersecciones_agentes.disconnect();
	}

	setSubscripciones(){

	}

	detectChanges(){

		this._cdRef.detectChanges();
	}

	readSeccionURL(){

        this._route.fragment.subscribe((seccion) => {

            if ( seccion ) 
                this.goSeccion(seccion);
        });
    }

	goSeccion(id=null) {
    	//console.log('goSeccion ir a : ', id);

		var elem = document.getElementById(id) || document.getElementsByName(id)[0];
		var top = 0;

		if (!elem) return;

		top = elem.offsetTop;

        this._scrollService.scrollTo({ y: top, duration: 800 });
	}

    setImagenesInicioPorDefecto(){

		this.url_imagenes_seccion_inicio = '/assets/images/';

		this.imagenes_seccion_inicio = [
			{ nombre: 'portada.png' },
			{ nombre: 'descripcion.jpg' }
        ];

		this.detectChanges();
	}

	setObservadorDeIntersecciones(){

		var options = {
		  root: null,
		  rootMargin: '0px', // Por defecto son todos 0 (top, right, bottom, left)
		  threshold: 0.35 //  porcentaje de visibilidad del elemento ( umbral )
		}

		this.observador_de_intersecciones = new IntersectionObserver(this.activeAnimacion, options);

		this.addObservablesAlObservador(this.observador_de_intersecciones, '.animated');
	}
	
	addObservablesAlObservador(observer, observable_select=null){

		if ( observable_select && typeof observable_select === 'string' ) {
			const targets = document.querySelectorAll(observable_select);

			targets.forEach((target) => {
				observer.observe(target);
			});
		}
	}

	activeAnimacion(entries, observer) {

		entries.forEach((entry)=>{

			if ( entry.isIntersecting ) {
				
				if ( entry.target.classList.contains('nosotros-titulo') )
					entry.target.classList.add('fadeIn');
				else if ( entry.target.classList.contains('nosotros-imagen') )
					entry.target.classList.add('fadeInLeft');
				else if ( entry.target.classList.contains('nosotros-texto') )
					entry.target.classList.add('fadeInRightBig');
				else if ( entry.target.classList.contains('servicio-info') ) {

					if ( entry.target.classList.contains('par') )
						entry.target.classList.add('fadeInRightBig');
					else
						entry.target.classList.add('fadeInLeftBig');
				} else if ( entry.target.classList.contains('servicio-imagen') ) {

					if ( entry.target.classList.contains('par') )
						entry.target.classList.add('fadeInLeft');
					else
						entry.target.classList.add('fadeInRight');
				} else if ( entry.target.classList.contains('resultado-inmueble') ) {

					if ( entry.target.classList.contains('par') )
						entry.target.classList.add('fadeIn');
					else
						entry.target.classList.add('fadeIn');
				}

				observer.unobserve(entry.target);
			}
		});
	}
}