<header id="site-header-desktop" class="site-header-desktop">
	<div class="container site-header-inner">
	  	<a class="site-logo" routerLink="/">
	    	<img src="/assets/images/caritas-argentina.png" alt="Caritas Pronto">
	  	</a>

	  	<nav>
		  	<ul>
				<li>
	                <a routerLink="/" fragment="portada" (click)="toggleMobileMenu()">Inicio</a>
	            </li>
	            <li>
	                <a routerLink="/" fragment="servicios" (click)="toggleMobileMenu()">Servicios</a>
	            </li>
	            <li>
	                <a routerLink="/" fragment="contactos" (click)="toggleMobileMenu()">Contactos</a>
	            </li>
			</ul>
		</nav>
	</div>
</header>

<header id="site-header-mobile" class="site-header-mobile">

	<div class="container site-header-inner">

		<a class="boton-menu" (click)="toggleMobileMenu()">
			<span></span><span></span><span></span><span></span>
		</a>

	  	<a class="site-logo" routerLink="/">
	    	<img src="/assets/images/caritas-argentina.png" alt="Caritas Pronto">
	  	</a>

	</div>

	<nav class="site-menu-mobile">
		<div class="site-menu-mobile-bg"></div>

		<div class="site-menu-inner">

			<ul class="site-menu-links">
				<li>
	                <a routerLink="/" fragment="inicio" (click)="toggleMobileMenu()">Inicio</a>
	            </li>
	            <li>
	                <a routerLink="/" fragment="servicios" (click)="toggleMobileMenu()">Servicios</a>
	            </li>
	            <li>
	                <a routerLink="/" fragment="contactos" (click)="toggleMobileMenu()">Contactos</a>
	            </li>
			</ul>

		</div>
	</nav>
</header>

<router-outlet></router-outlet>


<footer class="site-footer">

    <svg class="footer-svg" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" preserveAspectRatio="none" viewBox="0 0 1440 560">
        <g mask="url(&quot;#SvgjsMask1058&quot;)" fill="none">
            <rect width="1440" height="560" x="0" y="0" fill="url(#SvgjsLinearGradient1059)"></rect>
            <path d="M1440 0L1170.81 0L1440 44.61z" fill="rgba(255, 255, 255, .1)"></path>
            <path d="M1170.81 0L1440 44.61L1440 329.46000000000004L824.0699999999999 0z" fill="rgba(255, 255, 255, .075)"></path>
            <path d="M824.0699999999999 0L1440 329.46000000000004L1440 390.30000000000007L498.61999999999995 0z" fill="rgba(255, 255, 255, .05)"></path>
            <path d="M498.6199999999999 0L1440 390.30000000000007L1440 415.3400000000001L311.7199999999999 0z" fill="rgba(255, 255, 255, .025)"></path>
            <path d="M0 560L10.87 560L0 400.18z" fill="rgba(0, 0, 0, .1)"></path>
            <path d="M0 400.18L10.87 560L438.29 560L0 393.97z" fill="rgba(0, 0, 0, .075)"></path>
            <path d="M0 393.97L438.29 560L768.78 560L0 301.51000000000005z" fill="rgba(0, 0, 0, .05)"></path>
            <path d="M0 301.51L768.78 560L1010.92 560L0 152.73z" fill="rgba(0, 0, 0, .025)"></path>
        </g>
        <defs>
            <mask id="SvgjsMask1058">
                <rect width="1440" height="560" fill="#ffffff"></rect>
            </mask>
            <linearGradient x1="84.72%" y1="139.29%" x2="15.28%" y2="-39.29%" gradientUnits="userSpaceOnUse" id="SvgjsLinearGradient1059">
                <stop class="stop-color-primario" offset="0"></stop>
                <stop class="stop-color-secundario" offset="1"></stop>
            </linearGradient>
        </defs>
    </svg>

    <svg class="footer-svg svg-lineas" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" preserveAspectRatio="none" viewBox="0 0 1440 560">
        <g mask="url(&quot;#SvgjsMask1008&quot;)" fill="none">
            <path class="linea-1" d="M-98.89 250.14C8 250.38 112.01 320.14 322.91 320.14 533.81 320.14 525.23 247.73 744.71 250.14 964.19 252.55 1036.21 546.84 1166.51 563.33"></path>
            <path class="linea-3" d="M-2.7 183.44C146.05 189.99 245.08 433.09 534.29 441.04 823.51 448.99 930.22 612.4 1071.29 614.54"></path>
            <path class="linea-4" d="M-180.82 409.96C-69.76 410.18 38.52 479.96 257.87 479.96 477.21 479.96 474.64 409.57 696.55 409.96 918.47 410.35 1014.31 609.75 1135.24 614.71"></path>
            <path class="linea-2" d="M-156.86 101.22C-18.69 107.9 109.68 319.24 338.58 347.62 567.48 376 493.93 617.28 586.3 647.93"></path>
            <path class="linea-1" d="M-57.12 312.66C123.7 308.05 353.67 46.51 616.83 49.46 879.99 52.41 810.62 529 953.8 606.31"></path>
        </g>
        <defs>
            <mask id="SvgjsMask1008">
                <rect width="1440" height="560" fill="#ffffff"></rect>
            </mask>
        </defs>
    </svg>

	<div class="site-footer-bg-top-left">
        <div class="site-footer-cell"></div>
        <div class="site-footer-cell"></div>
        <div class="site-footer-cell"></div>
    </div>
	<div class="container">
		<div class="site-footer-inner">

			<address class="site-footer-contactos">
				<h3 class="site-footer-titulo">Información de contacto</h3>

                <ng-container>
                    <i class="mdi mdi-map-marker"></i>
                    Dirección
                </ng-container>

				<a href="tel:+5412323">
					<i class="mdi mdi-phone"></i> <p>-----</p>
				</a>

				<a href="tel:+54234234">
					<i class="mdi mdi-whatsapp"></i> <p>-----</p>
				</a>

				<a href="mailto:consulta@caritaspronto.org.ar">
					<i class="mdi mdi-email"></i> <p>consulta@caritaspronto.org.ar</p>
				</a>

                <a href="https://www.facebook.com/nose" target="_blank">
                    <i class="mdi mdi-facebook"></i> facebook
                </a>
                <a href="https://www.instagram.com/nose" target="_blank">
                    <i class="mdi mdi-instagram"></i> instagram
                </a>

			</address>

            <address class="site-footer-contactos">

                <h3 class="site-footer-titulo">Acceso Aplicaciones</h3>

                <a href="https://diocesis.caritaspronto.org.ar" target="_blank">
                    <i class="mdi mdi-family-tree"></i> Diocesis
                </a>
                <a href="https://parroquias.caritaspronto.org.ar" target="_blank">
                    <i class="mdi mdi-church"></i> Parroquias
                </a>

            </address>

			<div class="site-footer-logo">
				<a class="site-logo" routerLink="/">
			    	<img src="/assets/images/caritas-argentina.png" alt="Caritas Pronto" [style.background]="'#fff'">
			  	</a>
				<p>Caritas pronto <span>2022</span></p>
			</div>

		</div>
	</div>

    <div class="footer-bottom container">
        <p>Desarrollado por <a href="https://www.mardelinmueble.com" target="_blank">mardelinmueble.com</a></p>
    </div>

</footer>

<!-- SECCION FIJA DEL BOTON DEL WHATSAPP 
<a href="https://wa.me/5492235656" target="_blank" class="btn-whatsapp-fijo">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
      <path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fill-rule="evenodd">
      </path>
    </svg>

    <div class="btn-whatsapp-tooltip">
        Hola ! en que podemos ayudarte ?
    </div>
</a>
-->