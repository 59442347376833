<div class="carousel-imagenes"> 

	<ng-container *ngIf="images.length > 1">
		<div class="barra" *ngIf="show_progress_bar"></div>

		<a class="carousel-btn-previous" (click)="previous()" *ngIf="show_nav_buttons">
			<i class="mdi mdi-chevron-left"></i>
		</a>

		<a class="carousel-btn-next" (click)="next()" *ngIf="show_nav_buttons">
			<i class="mdi mdi-chevron-right"></i>
		</a>
	</ng-container>
	
	<!-- Gallery open -->
    <div class="carousel-btn-zoom" *ngIf="!touch_device && show_zoom_button" (click)="onZoom()">
    	<div class="carousel-btn-zoom-inner">
	        <span class="arrow-tl"></span>
	        <span class="arrow-tr"></span>
	        <span class="arrow-br"></span>
	        <span class="arrow-bl"></span>
	    </div>
    </div>

	<!-- [style.touch-action]="'pan-y'" ESTO ES NECESARIO PARA QUE EL USUARIO PUEDA HACER SCROLLING SOBRE LAS IMAGENES -->
	<div class="carousel" (panstart)="onPanStart($event)" (panmove)="onPanMove($event)" (panend)="onPanEnd($event)" [style.touch-action]="'pan-y'">
		
		<ng-container *ngFor="let image of images; index as i">
			<div class="image" (tap)="onTap(i)" [style.touch-action]="'pan-y'">
				<img src="{{ pathDirImages + image.nombre }}">
			</div>
		</ng-container>
		
	</div>

	<ol class="carousel-indicators" *ngIf="show_indicators">
		<li *ngFor="let image of images; index as i" [ngClass]="{ active: i == currentImage }">
		</li>
	</ol>

	<div class="carousel-btn-pause" (click)="pausa()" *ngIf="images.length > 1 && show_pause_button">
		<i class="mdi mdi-pause" *ngIf="!paused"></i>
		<i class="mdi mdi-play" *ngIf="paused"></i>
	</div>
</div>