import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
	selector: 'app-slider-imagenes',
	templateUrl: './slider-imagenes.component.html',
	styleUrls: ['./slider-imagenes.component.css',
	'../../../../assets/css/animations.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SliderImagenesComponent{

	@Input() imagenes: Array<any>;
	@Input() url_imagenes: string;
	@Input() tiempoPausa: number;
	@Input() velocidadEfecto: number;
	@Input() activateCarousel: 'true' | 'false' | boolean;
	@Input() show_buttons_nav: 'true' | 'false' | boolean;
	@Input() show_indicators: 'true' | 'false' | boolean;

	private timerRef: any = null;
	public pause: boolean;

	public currentSlide: number;
	public previousSlide: number;
	public slidesCount: number;

	constructor(private _cdRef: ChangeDetectorRef){

		//VALORES POR DEFECTO
		this.imagenes = [];
		this.pause = false;

		this.currentSlide = 1;
		this.slidesCount = 1;

		this.tiempoPausa = 10000; // 10 segundos
		this.velocidadEfecto = 1000;
		this.activateCarousel = true;
		this.show_buttons_nav = true;
		this.show_indicators = true;
	}

	ngOnChanges(change){

		if ( typeof this.activateCarousel == 'string' )
			this.activateCarousel =  this.activateCarousel == 'true' ? true : false;

		if ( typeof this.show_buttons_nav == 'string' )
			this.show_buttons_nav =  this.show_buttons_nav == 'true' ? true : false;

		if ( typeof this.show_indicators == 'string' )
			this.show_indicators =  this.show_indicators == 'true' ? true : false;

		this.slidesCount = this.imagenes.length;
		this.playCarousel();
	}

	ngOnDestroy(){

		this.clearInterval();
	}

	detectChanges(){

		this._cdRef.detectChanges();
	}

	isTouchDevice(){

		return (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
	}

	playCarousel(){

		if ( this.activateCarousel && this.imagenes.length > 1 ) {

			this.clearInterval();

			this.activarSlide();

			// Ahora creo la rutina
			// --------------------
			this.timerRef = setInterval(()=>{

				if ( !this.pause && document.visibilityState == "visible" ) {
					// Paso a la siguiente slide, si llego al ultimo, vuelvo al principio
					this.previousSlide = this.currentSlide;
					this.currentSlide = (this.currentSlide + 1 > this.slidesCount ) ? 1 : this.currentSlide + 1;

					this.detectChanges();

					this.activarSlide();
				}

		    }, this.tiempoPausa);
		}
	}

	activarSlide(){

        this.desactivarSlides()
        	.then(()=>{

		        let slide_activo = '#slide_'+this.currentSlide;
				$(slide_activo).addClass('slide-active');

				var timeoutID = setTimeout(()=>{
					$('.slider > .slider-inner > .slide > .slide-texto').addClass('fadeIn');
                    $('.slider > .slider-inner > .slide > .slide-texto-secundario').addClass('fadeInUp');
					clearTimeout(timeoutID);
				}, 500);

			});
    }

    desactivarSlides(): Promise<any>{

    	return new Promise((resolve)=>{
	    	$('.slider > .slider-inner > .slide > .slide-texto').removeClass('fadeIn');
            $('.slider > .slider-inner > .slide > .slide-texto-secundario').removeClass('fadeInUp');

	        $('.slider-inner .slide.slide-previous-active').addClass('clase-temp');
	        $('.slider-inner .slide.slide-active').addClass('slide-previous-active');

	        $('.slider-inner .slide.clase-temp').removeClass('slide-previous-active');
	        $('.slider-inner .slide.clase-temp').removeClass('clase-temp');

	        $('.slider-inner .slide.slide-active').removeClass('slide-active');

	        resolve(true);
	   	});
    }

    previous(){

    	this.previousSlide = this.currentSlide;
		this.currentSlide = (this.currentSlide - 1) < 1  ? this.slidesCount : this.currentSlide -1;

    	this.detectChanges();
		this.playCarousel();
	}

	next(){

		this.previousSlide = this.currentSlide;
		this.currentSlide = (this.currentSlide + 1 > this.slidesCount ) ? 1 : this.currentSlide + 1;

		this.detectChanges();
		this.playCarousel();
	}

	getClaseRandom(index: number= null) {

		let clase = 'celda' + this.getRandomIntInclusive(1, 10);

 		return clase;
    }

    getRandomIntInclusive(min, max) {

		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	private clearInterval(){

	    if (this.timerRef) {
	      	clearInterval(this.timerRef);
	      	this.timerRef = null;
	    }
	}
}
